import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pageContainer bg_lightGrey hostessScan py-5 px-3" }
const _hoisted_2 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_form_field = _resolveComponent("form-field")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_localized_text, {
      localizedKey: "app_CheckByEmail",
      text: "Check by email",
      type: "h1",
      class: "hostessTitle mb-3"
    }),
    _createVNode(_component_form_field, {
      type: "email",
      modelValue: _ctx.emailForCheck,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.emailForCheck) = $event)),
      placeholderKey: "backoffice_Email",
      placeholderValue: "Email",
      labelKey: "backoffice_Email",
      labelValue: "Email",
      class: "blackText",
      required: ""
    }, null, 8, ["modelValue"]),
    _createElementVNode("button", {
      class: "primaryButton w-100 mt-4 ms-2",
      disabled: !_ctx.emailForCheck,
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.checkByEmail()))
    }, [
      _createVNode(_component_localized_text, {
        localizedKey: "app_Verifica",
        text: "Verifica"
      })
    ], 8, _hoisted_2)
  ]))
}