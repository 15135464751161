
import { Options, Vue } from 'vue-class-component';
import WrongScanModal from '../modals/wrongScanModal.vue';
import SuccessScanModal from '../modals/successScanModal.vue';
import { AppUserClient } from '@/services/Services';
import ErrorModal from '../modals/errorModal.vue';

@Options({
    components: {
    }
})
export default class ScanForWelcomeKit extends Vue {

    emailForCheck: string = "";
    userIdentifier: string = "";
    loaded: boolean = false;
    
    created() {
    }

    checkByEmail() {
        AppUserClient.getUserIdentifierBy(this.emailForCheck)
        .then(x => {
            if(!x) {
                this.$opModal.show(ErrorModal, {
                    title: "Utente non trovato!",
                    text: "L'utente non fa parte della community o non ha ancora fatto accesso alla webapp dell'evento",
                    callback: () => {
                        window.location.reload();
                    }
                })
            }
            else {
                this.userIdentifier = x;
                this.checkIfCanHaveWelcomeKit();
            }
        })
    }

    checkIfCanHaveWelcomeKit() {
        AppUserClient.checkIfIsOwner(this.userIdentifier)
        .then(x => {
            if(!x) {
                this.$opModal.show(ErrorModal, {
                    title: "Gadget per soli membri OWNER!",
                    text: "L'utente non risulta essere un membro OWNER della nostra community",
                    callback: () => {
                        window.location.reload();
                    }
                })
            }
            else {
                AppUserClient.setWelcomeKitTaken(this.userIdentifier)
                .then(x => {
                    if(!x) {
                        this.$opModal.show(SuccessScanModal, {
                            homeCallback: () => {
                                this.$opModal.closeLast();
                                this.$router.replace("/dashboard");
                            },
                            callback: () => {
                                window.location.reload();
                            }
                        })
                    }
                    else {
                        this.$opModal.show(WrongScanModal, {
                            rescueDate: x,
                            homeCallback: () => {
                                this.$opModal.closeLast();
                                this.$router.replace("/dashboard");
                            },
                            callback: () => {
                                window.location.reload();
                            }
                        })
                    }
                })
            }
        })
    }

}
